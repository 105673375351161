import { Link } from "gatsby"
import React from "react"

export default function FeatureCard(props) {
  return (
    <div className="relative max-w-md">
      <div className="text-white text-center lg:text-left px-6 py-8">
        <h2 className="text-3xl lg:text-left">{props.title}</h2>
        <p className="py-8 text-xs md:text-sm leading-relaxed md:leading-relaxed">
          {props.text}
        </p>
        <Link to="/contact-us">
          <button className="border border-white bg-white bg-opacity-0 hover:bg-opacity-10 duration-300 rounded-md uppercase px-12 py-3 text-xs tracking-widest">
            Learn More
          </button>
        </Link>
      </div>
      <div className="absolute inset-0 border border-white border-opacity-20 rounded-md backdrop-filter backdrop-blur-md shadow-glass -z-10"></div>
    </div>
  )
}
