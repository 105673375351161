import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import FeatureCard from "../components/FeatureCard"

import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Testimonial from "../components/Testimonial"
import ContactForm from "../components/ContactForm"
import Seo from "../components/Seo"

export default function WhatWeDo() {
  return (
    <Layout calendly>
      <Seo title="What We Do" image="/images/what-we-do.jpg" />
      <Hero
        title="What We Do"
        titleClassName="w-auto"
        image={
          <StaticImage
            src="../images/what-we-do.jpg"
            alt="Abstract image of an object similar to waves lit by red and blue lights."
            className="w-full h-full"
            quality={90}
            placeholder="dominantColor"
          />
        }
      >
        <div className="text-gray-200 mt-4 lg:mt-8 mb-7 sm:mb-3 md:mb-7 sm:w-11/12 md:w-auto lg:leading-relaxed">
          <p>Whatever you need, we can built it.</p>
        </div>
      </Hero>
      <section
        id="social-media-advertising"
        className="section feature-section"
      >
        <div className="absolute top-0 left-0 right-0 pt-20 bg-gradient-to-b from-black"></div>
        <div className="absolute inset-0 -z-10 bg-dark-lighter">
          <StaticImage
            src="../images/social-media-advertising.jpg"
            alt="An individual's face lit so that you can only see their eye."
            className="h-full"
            quality={90}
            objectPosition="23% 50%"
          />
        </div>
        <div className="sm:col-start-2">
          <FeatureCard
            title="Social Media Advertising"
            text="Our highly experienced team will construct a social media strategy and
        run your campaigns from start to finish. Our software will help optimise
        your social media advertisements and get the most out of each dollar of
        your ad budget."
          ></FeatureCard>
        </div>
      </section>
      <Testimonial
        client="Adam Agresta"
        clientTitle="CEO of Vyrl and Agresta's Advisory"
        text='"I’d like to thank Karan, Andrew and the SocialMindr team for the
        incredible work they have put into our campaign since inception. The
        SocialMindr team use state-of-the-art marketing technology that I have
        never witnessed before, and the results speak for themselves. I highly
        recommend their services!"'
        picture={
          <StaticImage
            src="../images/testimonial/adam-agresta.png"
            width={130}
            className="w-full"
            objectFit="contain"
          ></StaticImage>
        }
        logo={
          <StaticImage
            src="../images/testimonial/logo-adam-agresta.png"
            width={60}
            className="w-full"
            objectFit="contain"
          ></StaticImage>
        }
      ></Testimonial>
      <section id="social-media-automation" className="section feature-section">
        <div className="absolute inset-0 -z-10 bg-dark-lighter">
          <StaticImage
            src="../images/social-media-automation.jpg"
            alt="A screen filled with minified Javascript code."
            className="h-full"
            quality={90}
          />
        </div>
        <div>
          <FeatureCard
            title="Social Media Automation"
            text="Significantly reduce your cost per acquisition by taking advantage of our own social media tools. Our Instagram, Facebook and LinkedIn tools make lead generation affordable and helps you reach out to your target market en masse."
          ></FeatureCard>
        </div>
      </section>
      <Testimonial
        client="Justin Annesley"
        clientTitle="Head of Partnerships - Startcon"
        text='"The technology behind SocialMindr’s marketing strategy really helped keep our costs down and generate leads en masse."'
        picture={
          <StaticImage
            src="../images/testimonial/justin-annesley.png"
            width={130}
            className="w-full"
            objectFit="contain"
          ></StaticImage>
        }
        logo={
          <StaticImage
            src="../images/testimonial/logo-justin-annesley.png"
            width={200}
            quality={100}
            className="w-full"
            objectFit="contain"
          ></StaticImage>
        }
        logoClassName="w-36 mt-3"
      ></Testimonial>
      <section id="creative-hub" className="section feature-section">
        <div className="absolute inset-0 -z-10 bg-dark-lighter">
          <StaticImage
            src="../images/creative-hub.jpg"
            alt="A colourful parrot spreading one wing out as if gesturing."
            className="h-full"
            quality={90}
            objectPosition="75% 50%"
          />
        </div>
        <div className="sm:col-start-2">
          <FeatureCard
            title="Creative Hub"
            text="Creating stunning graphics and content for your potential customer to see is vital in today’s competitive landscape. Our team can help you design beautiful websites, flyers, business card, photos/video and any other collateral to help take your business presence to the next level."
          ></FeatureCard>
        </div>
      </section>
      <Testimonial
        client="Jason Hardie"
        clientTitle="Founder of Digital Village"
        text='"SocialMindr was able to specifically target my audience resulting in valid and relevant messaging. This turned into more qualified leads than we could handle."'
        picture={
          <StaticImage
            src="../images/testimonial/jason-hardie.png"
            width={130}
            className="w-full"
            objectFit="contain"
          ></StaticImage>
        }
        logo={
          <StaticImage
            src="../images/testimonial/logo-jason-hardie.png"
            width={200}
            quality={100}
            className="w-full"
            objectFit="contain"
          ></StaticImage>
        }
        logoClassName="w-36 mt-3"
      ></Testimonial>
      <section id="software-development" className="section feature-section">
        <div className="absolute inset-0 -z-10 bg-dark-lighter">
          <StaticImage
            src="../images/software-development.jpg"
            alt="A sleek laptop reflecting a vibrant sunset in a pitch black room."
            className="h-full w-full"
            quality={90}
            objectPosition="50% 35%"
          />
        </div>
        <div>
          <FeatureCard
            title="Software Development"
            text="Our Australia-based tech team is highly experienced in creating custom built web and app solution. From a simple landing page to a full-scale iOS or android app, we work to fit your requirements and budget."
          ></FeatureCard>
        </div>
      </section>
      <section className="section">
        <ContactForm />
      </section>
    </Layout>
  )
}
