import React from "react"

export default function Testimonial(props) {
  return (
    <section className="section min-h-0 text-center sm:max-w-md md:max-w-lg lg:max-w-xl mx-auto lg:py-28">
      <div className="w-20 md:w-32">{props.picture}</div>
      <p className="text-xs md:text-sm mt-6 mb-4 lg:mt-8 lg:mb-6 leading-relaxed">
        {props.text}
      </p>
      <div>
        <p>{props.client}</p>
        <p>{props.clientTitle}</p>
      </div>
      <div className={props.logoClassName}>{props.logo}</div>
    </section>
  )
}

Testimonial.defaultProps = {
  logoClassName: "w-10 md:w-16 mt-3",
}
